<template>
  <div class="about">
    <br /><br /><br /><br />
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1>This is an about page</h1>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "About"
};
</script>
