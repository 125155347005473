<template>
  <div class="about">
    <!-- <br /><br /><br /><br /> -->
    <v-container>
      <h1>Progress</h1>
      <br />
      <v-card class="mx-auto" max-width="1050" tile>
        <br />
        <v-col v-if="developments.length" :cols="flex" :offset="offset">
          <v-autocomplete
            v-model="developmentChosen"
            :items="developments"
            item-text="name"
            label="Choose Dev. - SmartSheets"
            chips
            shaped
            filled
            outlined
            prepend-icon="mdi-tag-heart"
            color="#111d5e"
            item-color="#111d5e"
          ></v-autocomplete>
        </v-col>
        <v-col v-if="developments2.length" :cols="flex" :offset="offset">
          <v-autocomplete
            v-model="developmentChosen2"
            :items="developments2"
            item-text="developmentName"
            label="Choose Dev. mySQL"
            chips
            shaped
            filled
            outlined
            prepend-icon="mdi-tag-heart"
            color="#111d5e"
            item-color="#111d5e"
            @change="getSubsections"
          ></v-autocomplete>
        </v-col>
        <v-col v-if="subsection.length" :cols="flex" :offset="offset">
          <v-autocomplete
            v-model="subsectionChosen"
            :items="subsection"
            item-text="subsectionName"
            label="Choose Block"
            chips
            shaped
            filled
            outlined
            prepend-icon="mdi-tag-heart"
            color="#111d5e"
            item-color="#111d5e"
            @change="getUnits"
          ></v-autocomplete>
        </v-col>
        <v-col v-if="units.length" :cols="flex" :offset="offset">
          <v-autocomplete
            v-model="unitChosen"
            :items="units"
            item-text="unitName"
            label="Choose Unit"
            chips
            shaped
            filled
            outlined
            prepend-icon="mdi-tag-heart"
            color="#111d5e"
            item-color="#111d5e"
            @change="getTaskTypes"
          ></v-autocomplete>
        </v-col>
        <v-col v-if="taskType.length" :cols="flex" :offset="offset">
          <v-autocomplete
            v-model="taskTypeChosen"
            :items="taskType"
            item-text="taskName"
            label="Task Category"
            chips
            shaped
            filled
            outlined
            prepend-icon="mdi-tag-heart"
            color="#111d5e"
            item-color="#111d5e"
            @change="getTasks"
          ></v-autocomplete>
        </v-col>
      </v-card>
      <v-card class="mx-auto" max-width="1050" tile v-if="items.length">
        <v-toolbar color="#111d5e" dark>
          <v-toolbar-title>Progress Items</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-list two-line>
          <v-list-item-group v-model="selected" multiple>
            <template v-for="item in items">
              <v-list-item
                :key="item.id"
                style="border-bottom: 1px solid black;"
                :id="item.id"
              >
                <template>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="item.taskDescription"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      v-if="item.progressID"
                      v-text="((item.done / item.price) * 100).toFixed(0) + '%'"
                    ></v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <div style="display: flex;">
                      <v-spacer></v-spacer>

                      <v-btn :id="item.id" @click="openPostDialog($event)" text>
                        <v-icon color="green">mdi-post</v-icon>
                      </v-btn>
                    </div>
                  </v-list-item-action>
                </template>
              </v-list-item>

              <!-- <v-divider v-if="items.length" :key="items.length"></v-divider> -->
            </template>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-container>
    <v-snackbar v-model="snackbar" bottom top>
      {{ snackBarMessage }}
      <v-btn color="pink" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-dialog v-model="dialog" persistent max-width="900px">
      <v-card>
        <v-card-title>
          <span class="headline">POst Progress</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="task"
                  required
                  readonly
                  v-model="taskId"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="progressId"
                  v-model="progressId"
                ></v-text-field>
              </v-col>
              <v-col cols="9">
                <v-text-field
                  label="Job Description"
                  v-model="taskDescription"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" align-text="left">
                <div>
                  <vue-numeric-input
                    v-model="done"
                    label="Testing"
                    :min="0"
                    :max="100"
                    :step="10"
                  ></vue-numeric-input>
                </div>
              </v-col>
              <v-col cols="9">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="saveProgress">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- </v-row> -->
  </div>
</template>

<script>
import axios from "axios";
let url = process.env.VUE_APP_BASEURL;
// let url = "http://localhost:3000";
export default {
  name: "Progress",
  data() {
    return {
      windowWidth: null,
      flex: 12,
      offset: 0,
      snackbar: false,
      snackBarMessage: "",
      developments: [],
      developmentChosen: [],
      developments2: [],
      developmentChosen2: [],
      subsection: [],
      subsectionChosen: [],
      units: [],
      unitChosen: [],
      taskType: [],
      taskTypeChosen: [],
      tasks: [],

      developmentParam: 0,
      subsectionParam: 0,
      unitParam: 0,
      taskTypeParam: 0,
      value: 0,

      dialog: false,
      taskId: null,
      progressId: null,
      taskDescription: null,
      unitNumber: null,
      done: null,
      itemToPost: [],
      menu: false,
      date: new Date().toISOString().substr(0, 10),

      selected: [0],
      items: []
    };
  },
  async mounted() {
    this.items = [];
    this.developments = [];
    this.developmentChosen = [];
    this.developments2 = [];
    this.developmentChosen2 = [];
    this.subsection = [];
    this.subsectionChosen = [];
    this.units = [];
    this.unitChosen = [];
    this.taskType = [];
    this.taskTypeChosen = [];
    this.tasks = [];

    this.developmentParam = 0;
    this.subsectionParam = 0;
    this.unitParam = 0;
    this.taskTypeParam = 0;
    await axios({
      method: "get",
      url: `${url}/development2`
    }).then(
      response => {
        console.log("Data2", response.data);
        this.developments2 = response.data;
      },
      error => {
        console.log(error);
      }
    );
  },
  methods: {
    saveProgress() {
      console.log("taskId", this.taskId);
      console.log("progressId", this.progressID);
      console.log("date", this.date);
      console.log("done", this.done);
      let data = {
        task: this.taskId,
        unitNumber: this.unitNumber,
        progress: this.done,
        progressDate: this.date,
        progressId: this.progressId
      };
      console.log(data);
      axios({
        method: "post",
        url: `${url}/progressPost`,
        data: data
      })
        .then(result => {
          this.dialog = false;
          console.log(result);
          this.getTasks();
        })
        .catch(() => {
          this.snackBarMessage = "There was an error, please try again later!";
          this.snackbar = true;
        });
      // this.getTasks()
      this.dialog = false;
    },
    openPostDialog(event) {
      let targetID = event.currentTarget.id;
      console.log("targetID", targetID);

      this.taskId = targetID;
      let itemToPost = this.items.filter(el => {
        return el.id == this.taskId;
      });
      this.progressId = itemToPost[0].progressID;
      this.taskDescription = itemToPost[0].taskDescription;
      if (
        parseInt(
          ((itemToPost[0].done / itemToPost[0].price) * 100).toFixed(0)
        ) == isNaN()
      ) {
        this.done = 0;
      } else {
        this.done = parseInt(
          ((itemToPost[0].done / itemToPost[0].price) * 100).toFixed(0)
        );
      }
      console.log(itemToPost[0].done / itemToPost[0].price);
      console.log("Done", this.done);

      this.unitNumber = itemToPost[0].unitNumber;
      // console.log(this.itemToPost)
      this.dialog = true;
    },
    test2() {
      console.log(this.items);
    },
    getSubsections() {
      let parameter = "";
      if (this.developments2.length) {
        let subsection = this.developments2.filter(el => {
          return el.developmentName === this.developmentChosen2;
        });
        console.log(subsection);
        parameter = subsection[0].id;
        this.developmentParam = parameter;
        console.log(parameter);
        axios({
          method: "get",
          url: `${url}/subsection/${parameter}`
        }).then(
          response => {
            console.log("subsection", response.data);
            this.subsection = response.data;
          },
          error => {
            console.log(error);
          }
        );
      }
    },
    getUnits() {
      let parameter = "";
      if (this.subsection.length) {
        let unit = this.subsection.filter(el => {
          return el.subsectionName === this.subsectionChosen;
        });
        parameter = unit[0].id;
        this.subsectionParam = parameter;
        console.log(parameter);
        console.log("Unit", unit);
        axios({
          method: "get",
          url: `${url}/getUnits/${this.developmentParam}/${this.subsectionParam}`
        }).then(
          response => {
            console.log("Units", response.data);
            this.units = response.data;
          },
          error => {
            console.log(error);
          }
        );
      }
    },
    getTaskTypes() {
      let parameter = "";
      if (this.units.length) {
        let unit = this.units.filter(el => {
          return el.unitName === this.unitChosen;
        });
        parameter = unit[0].id;
        this.unitParam = parameter;
        console.log("Unit Chosen", unit);
        console.log(this.unitParam);
        axios({
          method: "get",
          url: `${url}/taskTypes`
        }).then(
          response => {
            console.log("TaskTypes", response.data);
            this.taskType = response.data;
          },
          error => {
            console.log(error);
          }
        );
      }
    },
    getTasks() {
      let parameter = "";
      if (this.taskType.length) {
        let taskType = this.taskType.filter(el => {
          return el.taskName === this.taskTypeChosen;
        });

        parameter = taskType[0].id;
        this.taskTypeParam = parameter;
        console.log("taskType Chosen", taskType);
        console.log(parameter);

        axios({
          method: "get",
          url: `${url}/tasks/${this.unitParam}/${this.taskTypeParam}`
        }).then(
          response => {
            console.log("Tasks", response.data);
            this.tasks = response.data;
            this.items = response.data.filter(el => {
              // let done = parseInt(el.done)
              return (el.done / el.price) * 100 != 100;
            });
            this.items.forEach(el => {
              el.editDate = new Date().toISOString().substr(0, 10);
            });

            // console.log(this.items)
          },
          error => {
            console.log(error);
          }
        );
      }
    },
    test() {
      axios({
        method: "get",
        url: `${url}/development`
      }).then(
        response => {
          console.log("Data", response.data);
          this.developments = response.data;
        },
        error => {
          console.log(error);
        }
      );
    }
  }
};
</script>

<style scoped>
.listDiv {
  display: flex;
  justify-content: space-evenly;
}
</style>
